const marketModel = {
  namespace: "cattery",
  state: {
    catteryId: 0,
    catId: 0
  },

  reducers: {
    changeMarket(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {},
  subscriptions: {},
};

export default marketModel;
