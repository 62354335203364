
import React, {lazy,Suspense} from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loading from "../components/Loading"
const HomepageView = lazy(() => import('../views/homepage'))
const BreedIndex = lazy(() => import('../views/breed'))
const BreedList = lazy(() => import('../views/breedList'))
const IntroductionView = lazy(() => import('../views/Introduction'))
const ByColorView = lazy(() => import('../views/byColor'))
const ByConditionView = lazy(() => import('../views/byCondition'))
const CatteryView = lazy(() => import('../views/cattery'))
const CatteryListView = lazy(() => import('../views/catteryList'))




export default function RouteApp(props) {
  return (
      <div className='route-dom'>
        <ConnectedRouter history={props.history}>
          <Router>
            <Suspense fallback={<Loading />} >
            <Switch>
              <Route path="/" component={HomepageView} exact />
              <Route path="/homepage" component={HomepageView} />
              <Route path="/breed" component={BreedIndex} />
              <Route path="/breed-list" component={BreedList} />
              <Route path="/introduction/:catId" component={IntroductionView} />
              <Route path="/byColor" component={ByColorView} />
              <Route path="/byCondition" component={ByConditionView} />
              <Route path="/cattery" component={CatteryView} />
              <Route path="/cattery-list/:catteryId" component={CatteryListView} />
            </Switch>
            </Suspense>
          </Router>
        </ConnectedRouter>
 
      </div>
  )
}
