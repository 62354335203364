const filterModel = {
  namespace: "filter",

  state: {
    more: 0,
    less: 0,
    priceId: 0,
    color: "白色",
    conditionId: 0
  },

  reducers: {
    changeFilter(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {},
  subscriptions: {},
};
export default filterModel
