export default {
    namespace: 'nav',
    state: {
     navId: 0,
    },
  
    reducers: {
      changeNav(state, { payload }) {
        return { ...state, ...payload };
      },
    },
  
    effects: {},
  
    subscriptions: {},
  };
  