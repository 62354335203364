/**
 * 注册 model
 *
 * @param {Object} app 实例
 * @param {Object} models models
 */
export function registerModel(app, models) {
  for (const i in models) {
    if (i in models) {
      const model = models[i]
      app.model(model)
    }
  }
}
